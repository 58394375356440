<template>
  <view-item title="配件出库明细">
    <template #operation>
      <button-ajax :method="operation.export.click">导出</button-ajax>
    </template>

    <en-card body-class="h-full" class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-tabs v-model="tabs.active">
            <en-tab-pane label="销售出库" name="sale" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="SALODFD"
                    :height="height"
                    :data="sale.data"
                    :method="sale.get"
                    :loading="sale.loading"
                    pagination
                    :paging="sale.paging"
                    show-summary
                    :summary="sale.summary.data"
                    :config="sale.config"
                  ></table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>

            <en-tab-pane label="销售退货" name="refund" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="SALRDFD"
                    :height="height"
                    :data="refund.data"
                    :method="refund.get"
                    :loading="refund.loading"
                    pagination
                    :paging="refund.paging"
                    show-summary
                    :summary="refund.summary.data"
                    :config="refund.config"
                  ></table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>

            <en-tab-pane label="其他出货" name="other" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="SALOTDFD"
                    :height="height"
                    :data="other.data"
                    :method="other.get"
                    :loading="other.loading"
                    pagination
                    :paging="other.paging"
                    show-summary
                    :summary="other.summary.data"
                    :config="other.config"
                  ></table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>

            <en-tab-pane label="移库出库" name="transfer" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="STFOGFD"
                    :height="height"
                    :data="transfer.data"
                    :method="transfer.get"
                    :loading="transfer.loading"
                    pagination
                    :paging="transfer.paging"
                    show-summary
                    :summary="transfer.summary.data"
                    :config="transfer.config"
                  ></table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>
          </en-tabs>
        </template>
      </flex-box>
    </en-card>
  </view-item>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        children: {
          export: {
            ajax: {
              sale: {
                action: 'GET /enocloud/stock/out/export/goods',
                params(params) {
                  params.payload = this.sale.$ajaxParams.payload
                }
              },
              refund: {
                action: 'GET /enocloud/sale/return/goods/export',
                params(params) {
                  params.payload = this.refund.$ajaxParams.payload
                }
              },
              other: {
                action: 'GET /enocloud/sale/other/goods/export',
                params(params) {
                  params.payload = this.other.$ajaxParams.payload
                }
              },
              transfer: {
                action: 'GET /enocloud/stock/transfer/out/goods/export',
                params(params) {
                  params.payload = this.sale.$ajaxParams.payload
                }
              }
            },
            async click() {
              await this.operation.export[this.tabs.active as 'sale' | 'refund' | 'other' | 'transfer']()
              return this.store.openDownload()
            }
          }
        }
      },
      tabs: {
        active: 'sale'
      },
      sale: {
        ajax: {
          get: {
            action: 'GET /enocloud/sale/goods/query',
            summary: 'GET /enocloud/sale/goods/summary',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { typeCode: 'SAL' }
            }
          }
        },
        config: {
          CLIENT_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customerId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer',
                    params: (params, value) => (params.payload = { name: value, rsexp: 'id,name', pagingEnabled: false })
                  },
                  filterable: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          GOODS_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsSerialNo'
              }
            }
          },
          GOODS_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsName'
              }
            }
          },
          STOCK_OUT_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'stockOutGoods.serialNo'
              }
            }
          },
          BATCH_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'stockOutGoods.batchNo'
              }
            }
          },
          GOODS_OEM: {
            header: {
              filter: {
                type: 'text',
                field: 'oem'
              }
            }
          },
          GOODS_BARCODE: {
            header: {
              filter: {
                type: 'text',
                field: 'goods.barcode'
              }
            }
          },
          GOODS_MODEL: {
            header: {
              filter: {
                type: 'text',
                field: 'goods.model'
              }
            }
          },
          SUPPLIER_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'supplierName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer',
                    params: (params, value) => (params.payload = { name: value,   type: 'B',
                      rsexp: 'id,name',  })
                  },
                   filterable: true,

               
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          WAREHOUSE_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'warehouseId',

                props: {
                  ajax: {
                    action: 'GET /enocloud/common/warehouse',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          SETTLEMENT_PROGRESS: {
            header: {
              filter: {
                type: 'select',
                field: 'settlementProgressCode',

                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['STLMTPRG']
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SALE_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'serialNo'
              }
            }
          },
          COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'comment'
              }
            }
          },
          SALE_COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'saleComment'
              }
            }
          },
          DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['startDate', 'endDate'],
                props: { type: 'daterange' }
              }
            }
          },
          SETTLING_ACCOUNT_METHOD: {
            header: {
              filter: {
                type: 'select',
                field: 'settlingAccountMethodCode',

                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['STLACMTD']
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          PREPARED_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'preparedById',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params) => (params.payload = { rsexp: 'id,name' })
                  },
                  filterable: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          AUDIT_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'auditById',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params) => (params.payload = { rsexp: 'id,name' })
                  },
                  filterable: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          INVOICE_TYPE_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'invoiceTypeName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/hint/:hintTypeStr',
                    params: (params) => (params.paths = ['INVCTP'])
                  },
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          }
        }
      },
      refund: {
        ajax: {
          get: {
            action: 'GET /enocloud/sale/goods/query',
            summary: 'GET /enocloud/sale/goods/summary',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { typeCode: 'SALR' }
            }
          }
        },
        config: {
          GOODS_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsSerialNo'
              }
            }
          },
          STOCK_OUT_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'stockOutGoods.serialNo'
              }
            }
          },
          BATCH_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'stockOutGoods.batchNo'
              }
            }
          },
          GOODS_OEM: {
            header: {
              filter: {
                type: 'text',
                field: 'oem'
              }
            }
          },
          GOODS_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsName'
              }
            }
          },
          WAREHOUSE_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'warehouseId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/warehouse',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          SUPPLIER_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'supplierName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer',
                    params: (params, value) => (params.payload = { name: value,   type: 'B',
                      rsexp: 'id,name',  })
                  },
               
                  props: { label: 'name', value: 'name' },
                },
                
              }
            }
          },
          CLIENT_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customerId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer',
                    params: (params, value) => (params.payload = { name: value,  })
                  },
                  remote: true,
                  
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['startDate', 'endDate'],
                props: { type: 'daterange' }
              }
            }
          },
          SETTLEMENT_PROGRESS: {
            header: {
              filter: {
                type: 'select',
                field: 'settlementProgressCode',

                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['STLMTPRG']
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          REFERENCE_SALE_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'saleSerialNo'
              }
            }
          },
          SALE_RETURN_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'serialNo'
              }
            }
          },
          COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'comment'
              }
            }
          },
          GOODS_BARCODE: {
            header: {
              filter: {
                type: 'text',
                field: 'goods.barcode'
              }
            }
          },
          GOODS_MODEL: {
            header: {
              filter: {
                type: 'text',
                field: 'goods.model'
              }
            }
          },
          SALE_COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'saleComment'
              }
            }
          },
          SETTLING_ACCOUNT_METHOD: {
            header: {
              filter: {
                type: 'select',
                field: 'settlingAccountMethodCode',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['STLACMTD']
                      params.payload = { excludes: ['PR', 'DC'] }
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          PREPARED_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'preparedById',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          AUDIT_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'auditById',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          INVOICE_TYPE_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'invoiceTypeName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/hint/:hintTypeStr',
                    params: (params) => (params.paths = ['INVCTP'])
                  },
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          }
        }
      },
      other: {
        ajax: {
          get: {
            action: 'GET /enocloud/sale/goods/query',
            summary: 'GET /enocloud/sale/goods/summary',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { typeCode: 'OTH' }
            }
          }
        },
        config: {
          GOODS_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsSerialNo'
              }
            }
          },
          BATCH_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'stockOutGoods.batchNo'
              }
            }
          },
          STOCK_OUT_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'stockOutGoods.serialNo'
              }
            }
          },
          GOODS_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsName'
              }
            }
          },
          GOODS_OEM: {
            header: {
              filter: {
                type: 'text',
                field: 'oem'
              }
            }
          },
          SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'serialNo'
              }
            }
          },
          COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'comment'
              }
            }
          },
          GOODS_BARCODE: {
            header: {
              filter: {
                type: 'text',
                field: 'goods.barcode'
              }
            }
          },
          GOODS_MODEL: {
            header: {
              filter: {
                type: 'text',
                field: 'goods.model'
              }
            }
          },
          SALE_COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'saleComment'
              }
            }
          },
          CLIENT_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customerId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer',
                    params: (params, value) => (params.payload = { name: value, type: 'B', rsexp: 'id,name' })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['startDate', 'endDate'],
                props: { type: 'daterange' }
              }
            }
          },
          WAREHOUSE_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'warehouseId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/warehouse',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          PREPARED_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'preparedById',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          AUDIT_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'auditById',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          }
        }
      },
      transfer: {
        ajax: {
          get: {
            action: 'GET /enocloud/stock/transfer/out/goods/query',
            summary: 'GET /enocloud/stock/transfer/out/goods/summary',
            data: 'array',
            loading: true,
            pagination: true
          }
        },
        config: {
          GOODS_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsSerialNo'
              }
            }
          },
          GOODS_OEM: {
            header: {
              filter: {
                type: 'text',
                field: 'oem'
              }
            }
          },
          GOODS_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsName'
              }
            }
          },
          GOODS_BARCODE: {
            header: {
              filter: {
                type: 'text',
                field: 'goods.barcode'
              }
            }
          },
          GOODS_MODEL: {
            header: {
              filter: {
                type: 'text',
                field: 'goods.model'
              }
            }
          },
          FROM_WAREHOUSE: {
            header: {
              filter: {
                type: 'select',
                field: 'fromWarehouseId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/warehouse',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          TO_WAREHOUSE: {
            header: {
              filter: {
                type: 'select',
                field: 'toWarehouseId',

                props: {
                  ajax: {
                    action: 'GET /enocloud/common/warehouse',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          PREPARED_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'preparedById',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          AUDIT_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'auditById',

                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['startDate', 'endDate'],
                props: { type: 'daterange' }
              }
            }
          },
          AUDIT_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['startAuditDate', 'endAuditDate'],
                props: { type: 'daterange' }
              }
            }
          },
          TO_BRANCH_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'toBranchId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/branch',
                    params: (params, value) => (params.payload = { quickSearch: value })
                  },
                  remote: true,
                  props: { label: 'name', value: '' }
                }
              }
            }
          },
          FROM_BRANCH_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'fromBranchId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/branch',
                    params: (params, value) => (params.payload = { quickSearch: value })
                  },
                  remote: true,
                  props: { label: 'name', value: '' }
                }
              }
            }
          },
          COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'comment'
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.sale.get()
    this.refund.get()
    this.other.get()
    this.transfer.get()
  }
})
</script>
